body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/********** Template CSS **********/
/* :root {
  --primary: #FF800F;
  --secondary: #001064;
  --light: #F6F7F8;
  --dark: #010A35;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}
 */

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Navbar ***/

.navbar {
  padding: 15px;
  color: white;
  text-align: center;
  transition: top 1.5s, background-color 1.5s;
}

.sticky {
  position: fixed;
  height: 10%;
  top: 0;
  z-index: 111;
  background-color: transparent;
}

.sticky.scrolled {
  background-color: white;
  top: 0px;
  transition: .5s;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 10px 0;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  color: #A40000;
}

.nav-link {
  color: #A40000;
}

.nav-link.active .nav-link:hover {
  color: #002462;
}

.scrolled .nav-link {
  color: #A40000;
  background-color: white;
  transition: top 1.5s, background-color 1.5s;
}

.scrolled .nav-link:hover {
  color: #002462;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #002462;
}

.collapse {
  background-color: #ffffffcc;
  border-radius: 10px;
}

.Logo_theX {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  .navbar-toggler {
    border-color: rgb(0, 0, 0) !important;
  }

  .navbar {
    text-align: center;
    background-color: white;
  }

  #navbarCollapse {
    background-color: white;
  }

  .nav-link {
    color: #A40000;
  }

  .nav-link.active .nav-link:hover {
    color: #002462;
    background-color: white;
  }

  .scrolled .nav-link {
    color: #A40000;
    background-color: white;
  }

  .Navcss {
    background-color: white;
  }

  .Logo_theX {
    width: 90%;
  }
}


/*** Header ***/
.carousel-caption {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgb(10 45 104/60%);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  width: 15%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 3rem;
  height: 3rem;
  background-color: var(--dark);
  border: 12px solid var(--dark);
  border-radius: 3rem;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.fab {
  color: #A40000;
  ;
}

.fab:hover {
  color: #002462;
}



/*** Header ***/

.carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(-225deg,
      #a50100 0%,
      #ffffff 29%,
      #ff1361 67%,
      #a50100 100%);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

/* .slideInDown{
  animation-name: :
} */

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}


@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.my-btn {
  color: #fff;
  padding: 10px 20px;
  background-color: #a40000;
  font-weight: 600;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  text-decoration: none;
}

.my-btn:hover {
  background-color: #002462;
}

/* Home Css end */


/* navbar css Start*/
.navbar {
  padding: 15px;
  color: white;
  text-align: center;
  transition: top 1.5s, background-color 1.5s;
}

.sticky {
  position: fixed;
  height: 10%;
  top: 0;
  z-index: 111;
  background-color: transparent;
}

.sticky.scrolled {
  background-color: white;
  top: 0px;
  transition: .5s;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 10px 0;

  font-weight: 400;
  outline: none;
  cursor: pointer;
}

.nav-link {
  /* color: white; */
  color: #A40000;
}

.nav-link.active .nav-link:hover {

  color: #002462;
}


.scrolled .nav-link {
  color: #A40000;
  background-color: white;
  transition: top 1.5s, background-color 1.5s;
}

.scrolled .nav-link:hover {
  color: #002462;
}


.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #002462;
}

.collapse {
  background-color: #ffffffcc;
  border-radius: 10px;
}

.Logo_theX {
  width: 40%;
}


@media only screen and (max-width: 600px) {

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .navbar-toggler {
    border-color: rgb(0, 0, 0);
  }

  .navbar {
    text-align: center;
    background-color: white;
  }

  #navbarCollapse {
    background-color: white;
  }

  .nav-link {
    color: #A40000;
  }

  .nav-link.active .nav-link:hover {
    color: #002462;
    background-color: white;
  }

  .scrolled .nav-link {
    color: #A40000;
    background-color: white;
  }

  .Navcss {
    background-color: white;
  }

  .Logo_theX {
    width: 90%;
  }

}

/* navbar css End*/


/* Aboutus css end*/
.company_section {
  background-size: cover;
}

.company_section {
  width: 100%;
  float: left;
  background-image: url(../public/assets/images/port-1.jpeg);
  height: auto;
  padding-bottom: 25px;
  background-repeat: no-repeat;
}

.layout_padding {
  padding-top: 90px;
  padding-bottom: 0px;
}

.compant_taital {
  font-size: 14px;
  padding: 30px 20px;
  margin-top: 30px;
}

.compant_taital {
  width: 100%;
  float: left;
  font-size: 16px;
  color: #ffffff;
  border: 2px solid #f0e4e4;
  padding: 50px 30px;
  margin-top: 60px;
  margin-bottom: 60px;
}






/* counter */

.block-counter-1 {
  text-align: center;
}

@media (max-width: 991.98px) {
  .block-counter-1 .number {
    font-size: 2rem;
  }
}

.block-counter-1 .number {
  color: #A40000;
  font-size: 4rem;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.block-counter-1 .number,
.block-counter-1 .caption {
  display: block;
  line-height: 1;
}

.block-counter-1 .caption {
  font-size: 14px;
  letter-spacing: .05em;
}


.block-counter-1 .number:after {
  position: absolute;
  content: "";
  width: 50px;
  height: 3px;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(52, 58, 64, 0.3);
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Aboutus css end*/


/* contactus Css Start */

.get-card {
  background-color: #d9d9d9;
  padding: 55px;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.get-left {
  width: 25vw;
  overflow: hidden;
  border-radius: 5px;
}

.get-left img {
  width: inherit;
  object-fit: cover;
  transform: scale(2.3);
}

.get-right .contact {
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
}

.form,
.address {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
}

.form input,
textarea {
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 2px solid #000;
  padding: 10px 0;
  margin: 5px 0;
}

.usersubmit input {
  background-color: #A40000;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  width: min-content;
  padding: 15px;
  margin-bottom: 0;
  cursor: pointer;
  transition: 0.3s;
}

.usersubmit input:hover {
  background-color: #002462;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

h4 {
  margin-bottom: 5px;
}

@media screen and (max-width: 700px) {
  .get-card {
    flex-direction: column;
  }

  .get-left {
    width: 60vw;
    height: 40vh;
  }
}

@media screen and (max-width: 350px) {
  .get-container {
    overflow: hidden;
  }

  .get-left {
    height: 20vh;
  }

  .contact {
    flex-direction: column;
  }
}

/* contactus Css end */



/* Footer css Start*/
.fab {
  color: #A40000;
}

.fab:hover {
  color: #002462;
}

.nav-link {
  padding: 1px 0;
  color: #002462;
  outline: none;
  cursor: pointer;
}

.nav-link:hover,
.nav-link.active {
  color: #A40000;
}

.flag {
  background-image: url("../public/assets/images/x-flag.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Footer css end*/

.error-message {
  color: red;
  background-color: #ffe8e8;
}


/* .fixed-top {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: white;
  /* Adjust as needed * /
  z-index: 1030;
  /* Ensure it stays above other content * /
  padding: 10px;
  border-bottom: 1px solid #ddd;
  /* Optional for visual separation *  /
}

.close-btn {
  cursor: pointer;
  font-size: 20px;
  float: right;
} */

.fixed-top.popup {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white; /* Adjust as needed */
  z-index: 1030; /* Ensure it stays above other content */
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Optional for visual separation */
  box-shadow: 0 2px 5px rgba(0,0,0,0.3); /* Optional for a popup effect */
}

.close-btn {
  cursor: pointer;
  font-size: 20px;
  float: right;
}



/* *************************** impex Page ********************************** */

#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}


/*Fix whatsapp chat*/
.whatsapp-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25D366;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  animation: breathe 2s ease-in-out infinite;
}

/*Estilos solo al icono whatsapp*/
.whatsapp-btn i {
  color: #fff;
  font-size: 24px;
  animation: beat 2s ease-in-out infinite;
  text-decoration: none;
}

/*Estilos con animation contorno respirando*/
@keyframes breathe {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(37, 211, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*Estilos de animacion del icono latiendo*/
@keyframes beat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}




/* Impex footer */

* {
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.pg-footer {
  font-family: 'Roboto', sans-serif;
}


.footer {
    background-color: #D9D9D9;
    color: black;
}
.footer-wave-svg {
    background-color: transparent;
    display: block;
    height: 30px;
    position: relative;
    top: -1px;
    width: 100%;
}
.footer-wave-path {
    fill: white;
}

.footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 370px;
    position: relative;
}

.footer-content-column {
    box-sizing: border-box;
    float: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #fff;
}

.footer-content-column ul li a {
  color: #6c757d;
  text-decoration: none;
}

.footer-logo-link {
    display: inline-block;
}
.footer-menu {
    margin-top: 30px;
}

.footer-menu-name {
    color: black;
    font-size: 15px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-menu-list {
    list-style: none;
    margin-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
}
.footer-menu-list li {
    margin-top: 5px;
}

.footer-call-to-action-description {
    color: #6c757d;
    margin-top: 10px;
    margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
    background-color: #fffff2;
    color: #00bef0;
}
.button:last-of-type {
    margin-right: 0;
}
.footer-call-to-action-button {
    background-color: #A40000;
    border-radius: 21px;
    color: #fffff2;
    display: inline-block;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    padding: 12px 30px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .2s;
    cursor: pointer;
    position: relative;
}
.footer-call-to-action {
    margin-top: 30px;
}
.footer-call-to-action-title {
    color: black;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: .1em;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
    margin-bottom: 0;
    margin-top: 10px;
    color: #fff;
    text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
    color: #6c757d;
    text-decoration: none;
}


.footer-copyright {
    background-color: #a40000;
    color: #fff;
    padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.footer-copyright-text {
  color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 0;
}

.footer-copyright-link {
    color: #fff;
    text-decoration: none;
}







/* Media Query For different screens */
@media (min-width:320px) and (max-width:479px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width:480px) and (max-width:599px)  { /* smartphones, Android phones, landscape iPhone */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width:600px) and (max-width: 800px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */

}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */

}
@media (min-width:1281px) { /* hi-res laptops and desktops */

}




@media (min-width: 760px) {
  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 450px;
      position: relative;
  }

  .footer-wave-svg {
      height: 50px;
  }

  .footer-content-column {
      width: 24.99%;
  }
}
@media (min-width: 568px) {
  /* .footer-content-column {
      width: 49.99%;
  } */
}


